require('./bootstrap');


/*
$(document).ready(function() {
    $('.js-example-basic-single').select2();
    $('.js-example-basic-single').removeClass('select2-hidden-accessible');
});

$(document).ready(function() {
    $('.js-example-basic-multiple').select2();
});
*/